import React from 'react';

class About extends React.Component {
    render() {
        return (
            <div className="Section About">
                <h2>About</h2>
                <img alt="" src="/front.png"></img>

                <p> Rishi Mirchandani is an Indian-American pianist currently based in London. At 18, he <a href="#ravel">debuted</a> with the Pittsburgh Symphony Orchestra after amassing six first-prize awards in major national and international competitions for young artists.</p>
            
                <p> Mirchandani made his first of four appearances at Carnegie Hall in New York at the age of 12. He has also performed internationally in Germany, Italy, Portugal, and Spain.</p>

                <p> He has participated in masterclasses with a number of illustrious pianists, among them Richard Goode, Boris Berman, <a href="#langlang">Lang Lang</a>, Eldar Nebolsin, Wei-Yi Yang, and Ian Jones. Mirchandani's <a href="#preludes">2014 recording</a> of Nancy Galbraith's Three Preludes for Piano was featured on the acclaimed Centaur Records album <i>Strange Travels</i>, which garnered a spot on the "Want List" in <i>Fanfare Magazine</i>.</p>
            
                <p>As a teacher, Mirchandani maintains a successful private studio and previously served as a Teaching Artist for <a href="https://www.midoriandfriends.org/">Midori & Friends</a>. His students routinely receive top honors in exams and competitions, including invitations to perform in Carnegie Hall. He taught a masterclass at the Carnegie Mellon Preparatory School of Music in 2024, and he has taught undergraduate piano proficiency as a Teaching Fellow at the Mannes School of Music.</p>
                
                <p> Mirchandani is a native of Pittsburgh, where he studied with Luz Manríquez. In 2019, he graduated <i>summa cum laude</i> from Yale University with a bachelor's degree in mathematics. He earned Distinction in Mathematics and was one of twelve students from his class (top 1%) elected to Phi Beta Kappa as a junior. While pursuing his mathematics studies, he also studied piano with Elizabeth Parisot and founded the <a href="http://yalepiano.org/"  target="_blank" rel="noopener noreferrer">Yale Undergraduate Piano Collective</a>. At commencement, he received the Stanton Wheeler and Marcia Chambers Award for outstanding artistic contributions to campus life. </p>
                
                <p> In 2021, Mirchandani completed his master's degree at the Manhattan School of Music, where he was a winner of the President's Award scholarship and was named to the Dean's List in each of his four terms. He was a pupil of Jeffrey Cohen and Solomon Mikowsky. In 2023, Mirchandani earned his Professional Studies Diploma at the Mannes School of Music, where he studied with Thomas Sauer. He was given the coveted Steinway Award for artistic excellence and service to Mannes.</p>
            
                <p> Mirchandani is currently pursuing an Artist Diploma at the Royal College of Music in London. He is a student of Dmitri Alexeev, Ian Jones, and Andrew Zolinsky. He receives sponsorship from the institution as an RCM Scholar. </p>
            </div>
        )
    }
}

export default About;